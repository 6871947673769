export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/slider-home.component' /* webpackChunkName: "scripts/slider-home.component" */
                ).then(({ default: SliderHomeComponent }) => {
                    new SliderHomeComponent()
                })

                import(
                    '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
                ).then(({ default: AnimationService }) => {
                    new AnimationService()
                })
            }
        }
    }
}
